import React from 'react';
import { Link } from "../../plugins/gatsby-plugin-atollon"
import Newsletter from './newsletter';
import { Brand } from './icons';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__col'>
          <Link to='/' title='Follett &amp; Co' className='footer__logo'>
            <Brand />
          </Link>
          <p className='footer__desktop'>© Follett &amp; Co {(new Date().getFullYear())}. <Link to='https://lbdstudios.com.au'>LBD STUDIOS</Link></p>
          <ul className='footer__mobile'>
            <li>
              <Link to='https://www.instagram.com/follettco/'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92282C14.0378 3.92282 14.3784 4.26339 14.3784 4.68316C14.3784 5.10324 14.0378 5.44381 13.6178 5.44381C13.198 5.44381 12.8574 5.10324 12.8574 4.68316C12.8574 4.26339 13.198 3.92282 13.6178 3.92282ZM6.16642 2.10736H12.0954C14.3216 2.10736 16.1424 3.91911 16.1424 6.13303V12.0917C16.1424 14.3055 14.3217 16.117 12.0954 16.117H6.16642C3.94016 16.117 2.11914 14.3055 2.11914 12.0916V6.13298C2.11914 3.91911 3.94016 2.10736 6.16642 2.10736Z" fill="#42403D"/>
                </svg>
              </Link>
            </li>
            <li>
              <Link to='https://www.linkedin.com/company/follett-&-co-/'>
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.87793 4.30983H3.80155V14.499H0.87793V4.30983ZM1.76564 3.53534H1.74447C0.686148 3.53534 0 2.75643 0 1.77007C0 0.763542 0.706432 0 1.78592 0C2.86453 0 3.52775 0.761621 3.54892 1.76719C3.54892 2.75355 2.86453 3.53534 1.76564 3.53534ZM15.4999 14.5H12.1847V9.22627C12.1847 7.84614 11.6661 6.90491 10.5258 6.90491C9.65353 6.90491 9.16846 7.53976 8.94269 8.15347C8.85802 8.37245 8.87125 8.67883 8.87125 8.98617V14.5H5.58691C5.58691 14.5 5.62925 5.15885 5.58691 4.30983H8.87125V5.90895C9.06528 5.20976 10.1148 4.21187 11.7896 4.21187C13.8674 4.21187 15.4999 5.67845 15.4999 8.83346V14.5Z" fill="#42403D"/>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer__col'>
          <ul>
            <li>
              <Link to='/buy/'>Buy</Link>
            </li>
            <li>
              <Link to='/sold/'>Sold</Link>
            </li>
            <li>
              <Link to='/lease/'>Lease</Link>
            </li>
            <li>
              <Link to='/about/'>About</Link>
            </li>
            <li>
              <Link to='/contact/'>Contact</Link>
            </li>
          </ul>
          <ul className='footer__desktop'>
            <li>
              <Link to='https://www.instagram.com/follettco/'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92282C14.0378 3.92282 14.3784 4.26339 14.3784 4.68316C14.3784 5.10324 14.0378 5.44381 13.6178 5.44381C13.198 5.44381 12.8574 5.10324 12.8574 4.68316C12.8574 4.26339 13.198 3.92282 13.6178 3.92282ZM6.16642 2.10736H12.0954C14.3216 2.10736 16.1424 3.91911 16.1424 6.13303V12.0917C16.1424 14.3055 14.3217 16.117 12.0954 16.117H6.16642C3.94016 16.117 2.11914 14.3055 2.11914 12.0916V6.13298C2.11914 3.91911 3.94016 2.10736 6.16642 2.10736Z" fill="#42403D"/>
                </svg>
              </Link>
            </li>
            <li>
              <Link to='https://www.linkedin.com/company/follett-&-co-/'>
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.87793 4.30983H3.80155V14.499H0.87793V4.30983ZM1.76564 3.53534H1.74447C0.686148 3.53534 0 2.75643 0 1.77007C0 0.763542 0.706432 0 1.78592 0C2.86453 0 3.52775 0.761621 3.54892 1.76719C3.54892 2.75355 2.86453 3.53534 1.76564 3.53534ZM15.4999 14.5H12.1847V9.22627C12.1847 7.84614 11.6661 6.90491 10.5258 6.90491C9.65353 6.90491 9.16846 7.53976 8.94269 8.15347C8.85802 8.37245 8.87125 8.67883 8.87125 8.98617V14.5H5.58691C5.58691 14.5 5.62925 5.15885 5.58691 4.30983H8.87125V5.90895C9.06528 5.20976 10.1148 4.21187 11.7896 4.21187C13.8674 4.21187 15.4999 5.67845 15.4999 8.83346V14.5Z" fill="#42403D"/>
                </svg>
              </Link>
            </li>
            <li>
              <Link to='/privacy-policy/'> Privacy Statement</Link>
            </li>
          </ul>
        </div>
        <div className='footer__col'>
          <Newsletter />
          <ul className='footer__mobile'>
            <li>
              <Link to='/privacy-policy/'>Privacy Statement</Link>
            </li>
          </ul>
          <p className='footer__mobile'>© Follett &amp; Co {(new Date().getFullYear())}. <Link to='https://lbdstudios.com.au'>LBD STUDIOS</Link></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
